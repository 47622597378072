<template>
  <div class="desktopLayoutClass">
    <v-app >

          <!-- Dialog Start -->
    <div>
      <v-row justify="center">
        <v-dialog v-model="auth_error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              Unauthorized!
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogUnauthorized" style="background-color: #1467BF;">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              Something went wrong...
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogError" style="background-color: #1467BF;">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- Dialog Close -->

    <div
      v-show="show_header"
      style="
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-left: 5px;
      margin: -1px;

      "
        class="header"
      >
      <div style="display: flex; flex-direction: row; width: 100%" >
        <div
          style="width: 10%; padding-top: 6px"
          align="left"
          v-on:click="redirectToHomePage()"
          >
          <img
          src="https://img.icons8.com/material-outlined/20/000000/left.png"
          />
        </div>
        <div style="width: 80%" align="left">
        <span style="font-weight: 600; font-size: 15pt"
        >Information</span
        >
        </div>
      </div>
       <div
            v-if="!isLogedIn"
          style="width: 10%; padding-top: 2px; font-weight:700; color:#1467BF"
          align="left"
          v-on:click="redirectToLoginPage()"
          >
            login
        </div>
    </div>


<v-col cols="12" >
  <div style="display:flex; flex-direction:column; padding:5px">
    <div>
        <img class="imgClass" style="width:100%" src='@/assets/careCompanionTopBanner.png' >
    </div>
    <div style="margin-top:10px">
      <p style=" text-align: justify; line-height:25px"> {{ $t("Customer.CareCompanionStaticContent.First_Para") }}</p>

      <p style=" text-align: justify;line-height:25px">{{ $t("Customer.CareCompanionStaticContent.Second_Para") }}</p>
    </div>
    <div>
       <img class="imgClass" style="width:100%" src='@/assets/careCompanionServiceBanner.png' >
    </div>

    <div style="margin-top:10px;background-color:#e9f6ff;">

       <div  style="display:flex; flex-direction:row;">
           <div >
             <img width="65px" style=" margin-top:18px; padding:5px" src='@/assets/chat.png' >
           </div>
           <div  style="margin-left:10px;margin-top:18px; padding:10px">
             <p style=" text-align: justify;line-height:25px" >{{ $t("Customer.CareCompanionStaticContent.Box_content1") }}</p>
           </div>
         </div>

          <div  style="display:flex; flex-direction:row;">
           <div >
             <img width="65px" style=" margin-top:18px; padding:5px" src='@/assets/prescription.png' >
           </div>
           <div  style="margin-left:10px;margin-top:18px; padding:10px">
             <p style=" text-align: justify;line-height:25px" >{{ $t("Customer.CareCompanionStaticContent.Box_Content2") }}</p>
           </div>
         </div>

           <div  style="display:flex; flex-direction:row;">
           <div >
             <img width="65px" style=" margin-top:18px; padding:5px" src='@/assets/medicine.png' >
           </div>
           <div  style="margin-left:10px;margin-top:18px; padding:10px">
             <p style=" text-align: justify;line-height:25px" >{{ $t("Customer.CareCompanionStaticContent.Box_Content3") }}</p>
           </div>
         </div>

         <div  style="display:flex; flex-direction:row;">
           <div >
             <img width="65px" style=" margin-top:18px; padding:5px" src='@/assets/Cart.png' >
           </div>
           <div  style="margin-left:10px;margin-top:18px; padding:10px">
             <p style=" text-align: justify;line-height:25px" >{{ $t("Customer.CareCompanionStaticContent.Box_Content4") }}</p>
           </div>
         </div>
    </div>
    <div>
        <v-btn  rounded color="primary" width="85%" @click=redirectTOCareCopmpanion()>
                {{ $t("Customer.CareCompanionStaticContent.Chat_Now") }}
         </v-btn>
    </div>
  </div>
</v-col>
</v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import bottomnavbar from "./bottomnavbar.vue";

export default {
  name: "CareCompanionInfo",
  data() {
    return {
      auth_error_enable: false,
      error_enable: false,
      pageName: "DoctorConsultationInfo",
      isMobile:false,
      isLogedIn:false,
      show_header: true,
    };
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    if(this.$store.state.locale == 'en')
      document.title = "Care Companion Information"
    else
      document.title = 'ဆေးဝါးပညာရှင်များ၏ သတင်းအချက်အလက်များ'
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.currentCustomer = this.$cookies.get("customerToken");

    //logging page hit - start
    if(this.currentCustomer)
    {
      let x =  localStorage.getItem("currentURL")
      let pageName = "CareCompanionLandingScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName)
      console.log(document.referrer);
      var pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
      .then((logSuccess) =>{

      })
      .catch((logError) =>{

      });
    }
    //logging page hit - end
     this.isLogedIn = true;
  },
  components: {
    NavBar,
    bottomnavbar,
  },
  methods: {
    redirectTOCareCopmpanion(){
       this.$router.push({
        name: "careCompanion",
      });
    },
    closeDialogUnauthorized(){
    this.auth_error_enable = false;
    this.$router.push({
        name: "Login",
      });
    },
    redirectToLoginPage(){
         this.$router.push({
        name: "Login",
      });
    },
    closeDialogError(){
    this.error_enable = false;
    },

    redirectToHomePage() {
      this.$router.go(-1);
    },
    // redirectToCallpage(meetingId) {
    //   this.$router.push({
    //    path: '/customer/videocall/'+ meetingId
    //  })
    // }
  }
};
</script>

<style>
/* @media (min-width: 800px){
  .InfoMainClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
  .imgClass{
    width: 95% !important;
  }
  .iconClass{
    width: 90px !important;
  }
  .iconClass1{
    width: 33% !important;

  }
  .textClass{
    margin-top: 45px;
    margin-left:2px !important;
    text-align: left !important;

  }
   .textClass1{
    margin-top: 45px;
    text-align: left !important;
    margin-right: 10% !important;


  }
  } */
</style>